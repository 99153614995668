<script setup lang="ts">
import type { CfgBreadcrumbsPage } from '@cfgtech/ui'
import { CfgBreadcrumbs } from '@cfgtech/ui'

type Props = {
  pages: CfgBreadcrumbsPage[]
}

defineProps<Props>()

const router = useRouter()

function onBreadcrumbClick(path: string): void {
  router.push(path)
}
</script>

<template>
  <CfgBreadcrumbs :pages="pages" @link:click="onBreadcrumbClick" />
</template>
